import { CalendarId } from '@mero/api-sdk/dist/calendar';
import { PageId } from '@mero/api-sdk/dist/pages';
import { OrderId } from '@mero/api-sdk/dist/payments/order-id';
import { UserId } from '@mero/api-sdk/dist/users';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as E from 'fp-ts/lib/Either';
import { identity, pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { DateFromISOString, withFallback } from 'io-ts-types';

import log from './utils/log';

const SELECTED_PAGE_ID = '@mero-pro/selected-page';
const SUBSCRIPTION_EXPIRE_NOTIFICATIONS = '@mero-pro/subscription-expire-notifications';
const TRIAL_STARTED_NOTIFICATIONS = '@mero-pro/trial-started-notifications';
const NOTIFICATIONS_DISMISSED_AT = '@mero-pro/notifications-dismissed-at';
const CALENDAR_QUERY = '@mero-pro/calendar-query';
const PAYMENT_SUCCESSFUL = '@mero-pro/payment-successful';
const STRIPE_CODE = '@mero-pro/stripe-code';
const SCHEDULE_CHANGED = '@mero-pro/schedule-changed';
const PAGES_TO_DELETE = '@mero-pro/pages-to-delete';
const PUBLISH_BANNER_STATE = '@mero-pro/publish-banner-state';
const SELECTED_PRODUCTS_TAB = '@mero/selected-products-tab';
const WEB_CONSENT = '@mero/web-consent';

const setTypedItem = async <C extends t.Mixed>(key: string, codec: C, value: t.TypeOf<C>): Promise<void> => {
  await AsyncStorage.setItem(key, JSON.stringify(codec.encode(value)));
};

const getTypedItem = async <C extends t.Mixed>(key: string, codec: C, failedValue?: unknown): Promise<t.TypeOf<C>> =>
  pipe(
    await AsyncStorage.getItem(key),
    t.union([t.null, t.undefined, t.string]).decode,
    E.chain(
      (s): E.Either<unknown, t.TypeOf<C>> =>
        s
          ? pipe(
              E.parseJSON(s, identity),
              E.chain((o) =>
                pipe(
                  o,
                  codec.decode,
                  E.mapLeft((e): unknown => e),
                ),
              ),
              E.mapLeft((e): unknown => e),
            )
          : E.right<unknown, t.TypeOf<C>>(failedValue),
    ),
    E.fold((e) => {
      log.error(`Failed to decode AsyncStorage data at ${key}: ${e}`);
      return failedValue;
    }, identity),
  );

export type AppStorage = {
  /**
   * Get saved current user page
   */
  getCurrentUserPageId: () => Promise<PageId | undefined>;

  /**
   * Save selected user page
   */
  setCurrentUserPageId: (pageId: PageId) => Promise<void>;

  /**
   * Returns the Date user was notified about subscription expiration
   */
  getSubscriptionExpireNotifiedAt: (pageId: PageId) => Promise<Date | undefined>;

  /**
   * Save the Date user was notified about subscription expiration
   */
  setSubscriptionExpireNotifiedAt: (pageId: PageId, ts: Date) => Promise<void>;

  /**
   * Save the date user was notified about trial period start
   */
  setTrialStartedNotifiedAt: (pageId: PageId, ts: Date) => Promise<void>;

  /**
   * Returns the Date user was notified about trial period start
   */
  getTrialStartedNotifiedAt: (pageId: PageId) => Promise<Date | undefined>;
  /**
   * Get timestamp user dismissed notifications prompt at
   */
  getNotificationsDismissedAt: () => Promise<Date | undefined>;

  /**
   * Save user dismissed notifications prompt (at given time)
   */
  setNotificationsDismissedAt: (ts: Date) => Promise<void>;

  /**
   * Get calendar query state
   */
  getCalendarQuery: (userId: UserId, pageId: PageId) => Promise<CalendarQuery | undefined>;

  /**
   * Save calendar query state
   */
  setCalendarQuery: (userId: UserId, pageId: PageId, query: CalendarQuery) => Promise<void>;

  /**
   * Save last order that payment was successful
   */
  setLastPaymentId: (orderId: OrderId) => Promise<void>;

  /**
   * Get last order that payment was successful
   */
  getLastPaymentId: () => Promise<OrderId | undefined>;

  /**
   * Save Stripe code
   */
  setStripeCode: (code: string) => Promise<void>;

  /**
   * Get Stripe code
   */
  getStripeCode: () => Promise<string | undefined>;

  /**
   * Delete Stripe code
   */
  deleteStripeCode: () => Promise<void>;

  /**
   * Save address changed status
   */
  setScheduleChanged: (pageId: PageId) => Promise<void>;

  /**
   * Get address changed status
   */
  getScheduleChanged: () => Promise<PageId | undefined>;

  /**
   * Delete address changed status
   */
  deleteScheduleChanged: () => Promise<void>;

  /**
   * Add a page on the pending list to be deleted
   * @param pageId
   */
  addPageToDelete: (pageId: PageId) => Promise<void>;

  /**
   * Get the list of pages pending deletion
   */
  getPagesToDelete: () => Promise<PageId[]>;

  /**
   * Remove a page from the pending list to be deleted
   * @param pageId
   */
  removePageToDelete: (pageId: PageId) => Promise<void>;

  /**
   * Remove all pages from the pending list to be deleted
   */
  clearPagesToDelete: () => Promise<void>;

  /**
   * Save publish banner state
   * @param pageId
   * @param state
   */
  setPublishBannerHideState: (pageId: PageId, state: boolean) => Promise<void>;

  /**
   * Get publish banner state
   * @param pageId
   */
  getPublishBannerHideState: (pageId: PageId) => Promise<boolean>;

  /**
   * Delete publish banner state
   * @param pageId
   */
  deletePublishBannerHideState: (pageId: PageId) => Promise<void>;

  /**
   * Save selected tab from products
   */
  setSelectedProductsTab: (tab: string) => Promise<void>;

  /** Get selected tab from products
   *
   */
  getSelectedProductsTab: () => Promise<string | undefined>;

  /**
   * Save web consent
   */
  setWebConsent: (value: boolean) => Promise<void>;

  /**
   * Get web consent
   */
  getWebConsent: () => Promise<boolean>;
};

const SubscriptionExpireNotifications = t.record(t.string, DateFromISOString);
type SubscriptionExpireNotifications = t.TypeOf<typeof SubscriptionExpireNotifications>;

const PublishBannerStateMap = t.record(PageId, t.boolean);
type PublishBannerStateMap = t.TypeOf<typeof PublishBannerStateMap>;

const getSubscriptionExpireNotifications = async (): Promise<SubscriptionExpireNotifications> =>
  await getTypedItem(SUBSCRIPTION_EXPIRE_NOTIFICATIONS, SubscriptionExpireNotifications, {});

const TrialStartedNotifications = t.record(t.string, DateFromISOString);
type TrialStartedNotifications = t.TypeOf<typeof TrialStartedNotifications>;

const getTrialStartedNotifications = async (): Promise<TrialStartedNotifications> =>
  await getTypedItem(TRIAL_STARTED_NOTIFICATIONS, TrialStartedNotifications, {});

const CalendarQuery = t.intersection([
  t.union(
    [
      t.type({
        period: t.literal('day'),
        isAll: t.literal(true),
      }),
      t.type({
        period: t.literal('day'),
        isAll: t.literal(false),
        calendarIds: t.array(CalendarId),
      }),
      t.type({
        period: t.literal('week'),
        calendarId: CalendarId,
      }),
    ],
    'CalendarQuery',
  ),
  t.type({
    includeDeleted: withFallback(t.boolean, false),
    hasFinishedCheckoutTransactions: withFallback(t.boolean, true),
    activeOnly: withFallback(t.boolean, false),
    showOnlyWorkingHours: withFallback(t.boolean, false),
    calendarsOrder: withFallback(t.array(CalendarId), []),
  }),
]);

type CalendarQuery = t.TypeOf<typeof CalendarQuery>;

const CalendarQueryMap = t.record(UserId, t.record(PageId, CalendarQuery));
type CalendarQueryMap = t.TypeOf<typeof CalendarQueryMap>;

export const AppStorage: AppStorage = {
  getCurrentUserPageId: async () =>
    pipe(
      await AsyncStorage.getItem(SELECTED_PAGE_ID),
      t.union([t.null, t.undefined, PageId]).decode,
      E.fold(
        () => undefined,
        (pageId) => pageId ?? undefined,
      ),
    ),
  setCurrentUserPageId: async (pageId) => {
    await AsyncStorage.setItem(SELECTED_PAGE_ID, PageId.encode(pageId));
  },
  getSubscriptionExpireNotifiedAt: async (pageId) => pipe(await getSubscriptionExpireNotifications(), (s) => s[pageId]),
  setSubscriptionExpireNotifiedAt: async (pageId, ts) => {
    const notifications = await getSubscriptionExpireNotifications();
    const item: SubscriptionExpireNotifications = {
      ...notifications,
      [pageId]: ts,
    };

    await setTypedItem(SUBSCRIPTION_EXPIRE_NOTIFICATIONS, SubscriptionExpireNotifications, item);
  },
  setTrialStartedNotifiedAt: async (pageId, ts) => {
    const notifications = await getTrialStartedNotifications();
    const item: TrialStartedNotifications = {
      ...notifications,
      [pageId]: ts,
    };

    await setTypedItem(TRIAL_STARTED_NOTIFICATIONS, TrialStartedNotifications, item);
  },
  getTrialStartedNotifiedAt: async (pageId) => pipe(await getTrialStartedNotifications(), (s) => s[pageId]),
  getNotificationsDismissedAt: async () =>
    pipe(
      await AsyncStorage.getItem(NOTIFICATIONS_DISMISSED_AT),
      t.union([t.null, t.undefined, DateFromISOString]).decode,
      E.fold(
        () => undefined,
        (ts) => ts ?? undefined,
      ),
      (ts) => {
        log.debug(`AppStorage.getNotificationsDismissedAt() = "${ts?.toISOString()}"`);
        return ts;
      },
    ),
  setNotificationsDismissedAt: async (ts) => {
    await AsyncStorage.setItem(NOTIFICATIONS_DISMISSED_AT, DateFromISOString.encode(ts));
  },
  getCalendarQuery: async (userId: UserId, pageId: PageId): Promise<CalendarQuery | undefined> => {
    const data: CalendarQueryMap = await getTypedItem(CALENDAR_QUERY, CalendarQueryMap, {});

    if (userId in data && pageId in data[userId]) {
      return data[userId][pageId];
    }

    return undefined;
  },
  setCalendarQuery: async (userId: UserId, pageId: PageId, query: CalendarQuery): Promise<void> => {
    const data: CalendarQueryMap = await getTypedItem(CALENDAR_QUERY, CalendarQueryMap, {});

    await setTypedItem(CALENDAR_QUERY, CalendarQueryMap, {
      ...data,
      [userId]: {
        ...(userId in data ? data[userId] : {}),
        [pageId]: query,
      },
    });
  },
  setLastPaymentId: async (orderId: OrderId) => {
    await AsyncStorage.setItem(PAYMENT_SUCCESSFUL, OrderId.encode(orderId));
  },
  getLastPaymentId: async () =>
    pipe(
      await AsyncStorage.getItem(PAYMENT_SUCCESSFUL),
      t.union([t.null, t.undefined, OrderId]).decode,
      E.fold(
        () => undefined,
        (orderId) => orderId ?? undefined,
      ),
    ),
  setSelectedProductsTab: async (tab: string): Promise<void> => {
    await AsyncStorage.setItem(SELECTED_PRODUCTS_TAB, tab);
  },
  getSelectedProductsTab: async () =>
    pipe(
      await AsyncStorage.getItem(SELECTED_PRODUCTS_TAB),
      t.union([t.null, t.undefined, t.string]).decode,
      E.fold(
        () => undefined,
        (orderId) => orderId ?? undefined,
      ),
    ),

  setStripeCode: async (code: string) => {
    await AsyncStorage.setItem(STRIPE_CODE, t.string.encode(code));
  },

  getStripeCode: async () =>
    pipe(
      await AsyncStorage.getItem(STRIPE_CODE),
      t.union([t.null, t.undefined, t.string]).decode,
      E.fold(
        () => undefined,
        (orderId) => orderId ?? undefined,
      ),
    ),

  deleteStripeCode: async () => {
    await AsyncStorage.removeItem(STRIPE_CODE);
  },

  setScheduleChanged: async (value: PageId) => {
    await AsyncStorage.setItem(SCHEDULE_CHANGED, PageId.encode(value));
  },

  getScheduleChanged: async () =>
    pipe(
      await AsyncStorage.getItem(SCHEDULE_CHANGED),
      PageId.decode,
      E.fold(
        () => undefined,
        (value) => value,
      ),
    ),

  deleteScheduleChanged: async () => {
    await AsyncStorage.removeItem(SCHEDULE_CHANGED);
  },

  addPageToDelete: async (pageId: PageId) => {
    const pagesToDelete = await getTypedItem(PAGES_TO_DELETE, t.array(PageId), []);
    await setTypedItem(PAGES_TO_DELETE, t.array(PageId), [...pagesToDelete, pageId]);
  },

  getPagesToDelete: async () => {
    return await getTypedItem(PAGES_TO_DELETE, t.array(PageId), []);
  },

  removePageToDelete: async (pageId: PageId) => {
    const pagesToDelete = await getTypedItem(PAGES_TO_DELETE, t.array(PageId), []);
    await setTypedItem(
      PAGES_TO_DELETE,
      t.array(PageId),
      pagesToDelete.filter((id) => id !== pageId),
    );
  },

  clearPagesToDelete: async () => {
    await AsyncStorage.removeItem(PAGES_TO_DELETE);
  },

  setPublishBannerHideState: async (pageId: PageId, state) => {
    const publishBannerState = await getTypedItem(PUBLISH_BANNER_STATE, PublishBannerStateMap, {});
    await setTypedItem(PUBLISH_BANNER_STATE, PublishBannerStateMap, {
      ...publishBannerState,
      [pageId]: state,
    });
  },

  getPublishBannerHideState: async (pageId: PageId) => {
    const publishBannerState = await getTypedItem(PUBLISH_BANNER_STATE, PublishBannerStateMap, {});
    return Boolean(publishBannerState[pageId]);
  },

  deletePublishBannerHideState: async (pageId: PageId) => {
    const publishBannerState = await getTypedItem(PUBLISH_BANNER_STATE, PublishBannerStateMap, {});
    delete publishBannerState[pageId];
    await setTypedItem(PUBLISH_BANNER_STATE, PublishBannerStateMap, publishBannerState);
  },

  setWebConsent: async (value: boolean) => {
    await setTypedItem(WEB_CONSENT, t.boolean, value);
  },

  getWebConsent: async () => {
    return await getTypedItem(WEB_CONSENT, t.boolean, false);
  },
};
